.nowrap {
    white-space: nowrap;

}


.textInput{
    min-width: 250px;
    font-family: inherit;
    
}
.textInput.small{
    padding: 0px 2px;
    font-size: 8pt;
}

.textInput.medium{
    padding: 0px 5px !important;
    margin: -2px 0px !important;
    
    font-size: 10pt !important;
}

.asterix-div{
    
    margin-top: -5px;
    
}

.fieldValue{
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(150, 150, 150, 0.20);
    border-radius: 5px;
    min-height: 1.5em !important;
    min-width: 100px !important;
    position: relative;
    
}
.fieldValue.error{
    
    border: 2px solid red !important;
}
.fieldValue.isDefault .text{
    color: rgba(100, 100, 100) !important;
}
.fieldValue.empty .text{
    color: rgba(100, 100, 100, 0.5) !important;
}
.fieldValue.required{
    background-color: rgba(255, 255, 255, 0.4);
    
}

.fieldValue.required.empty{
    background-color: rgba(255, 255, 255, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
}



.fieldValue:hover{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(150, 150, 150, 0.60);
    
}
.fieldValue .editIcon{
    visibility: hidden; 
    position:absolute;
    right:-4px;
    top:-2px
    /* margin:2px; */
}
.fieldValue:hover .editIcon{
    visibility: visible; 

}


.fieldValueContent{
    
}


.fieldValue.multiline{
    flex: 1 0 auto;
}

.fieldValue.multiline textarea{
    white-space: pre-wrap !important;
    width: 100% !important;
    min-height: 4em !important;
    overflow-wrap: anywhere !important;
    
}
.fieldValueContent.multiline{
    white-space: pre-wrap;
    width: 100%;
    min-height: 4em;
    overflow-wrap: anywhere;
}
.fieldValueContent.multiline span{
    
    overflow-wrap: anywhere;
}
.fieldValue:hover .editIcon{
    visibility: visible; 
}

.fieldValue span, .readonlyFieldValue span{
    max-height: 500px;
    overflow-y: auto;
    
    overflow-x: hidden !important;
    
  }

table.autoUIGrid {
    table-layout: fixed;
    width: 100%; 
    
    
    
    
    /* border: 1px solid red; */
}

table.autoUIGrid tr td{
    vertical-align:top;
    padding: 5px;
    
    /* border: solid 1px rgba(150, 150, 150, 0.50); */
    
    
}

table.autoUIGrid  tr td:first-child{
    width: 20%; /* Set a minimum width for the first column */
    white-space: nowrap; /* Prevent line wrapping in the first column */
    min-width: 100px;
    
    
  }
  
  table.autoUIGrid tr td:last-child {
    /*max-width: 80%; /* Let the second column take up the remaining space */
    width: max-content;
  }

table.autoUIGrid.small {
    vertical-align:top;
}